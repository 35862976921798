/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
/**
 * Hook to excute a effect every <interval> ms
 *
 * Creates an interval excuted every <interval> ms
 *
 * @example
useInterval(() => fetchSomentinh(), 1000);
 */
export const useInterval = (
  callback: () => unknown,
  interval: number = 1000
) => {
  useEffect(() => {
    const timer = setInterval(() => {
      callback();
    }, interval);
    return () => {
      clearInterval(timer);
    };
  }, []);
};
