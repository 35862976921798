import { useMemo } from 'react';
import ScheduleAPI from '../../../../../service/schedule/ScheduleAPI';
import { StoreSchedulesUpdateRequiredAPIResponse } from '../../../../../service/schedule/ScheduleAPI.model';
import { SECONDS_POOL_DATA } from '../../../../../service/store/Store.constants';
import { useInterval } from '../../../../../shared/hooks/useInterval';
import useWatchError from '../../../../../shared/hooks/useWatchError';
import { useAppSelector } from '../../../../../redux/store.model';
import { getSchedulesUpdateRequiredState } from '../redux/selectors';
import { useTick } from '../../../../../shared/hooks/useTick';
import moment from 'moment';
import { TIME_REFRESH_NOW } from '../constants';
import useDevicesOperator from '../../../../../service/store/hooks/useDevicesOperator';

const dicEmpty: StoreSchedulesUpdateRequiredAPIResponse['devices'] = {};
function useDeviceWithRequireUpdateSchedules(usePeriodicFetch = false) {
  const {
    data: scheduleDevices = dicEmpty,
    isLoading: loadingScheduleDevices,
    error: errorScheduleDevices,
    refetch: refetchScheduleDevices,
    isFetching: isFetchingScheduleDevices,
  } = ScheduleAPI.useGetSchedulesUpdateRequiredByStoreQuery();
  const taskAsyncState = useAppSelector(getSchedulesUpdateRequiredState);
  const { devices, isFetching: loadingDevices } = useDevicesOperator();
  useWatchError(errorScheduleDevices);
  const devicesWithUpdateRequired = useMemo(
    () =>
      Object.entries(scheduleDevices).map(([deviceId, value]) => ({
        device: devices.find((device) => device.uuid === deviceId),
        deviceId,
        taskAsyncState: taskAsyncState?.[deviceId],
        ...value,
      })),
    [devices, scheduleDevices, taskAsyncState]
  );

  const devicesWithUpdateRequiredConnected = useMemo(
    () =>
      devicesWithUpdateRequired.filter(
        ({ device }) => device?.deviceState?.mqttConnected
      ),
    [devicesWithUpdateRequired]
  );
  const hasSomeDeviceDisconnected = !!(
    devicesWithUpdateRequired.length - devicesWithUpdateRequiredConnected.length
  );

  const now = useTick(() => moment().valueOf(), TIME_REFRESH_NOW);

  const devicesNeedUpdateRequired = useMemo(
    () =>
      // filter device required update
      devicesWithUpdateRequiredConnected
        .filter(({ updateRequired }) => updateRequired === true)

        // filter device not skipped
        .filter(
          ({ taskAsyncState, schedule }) =>
            !(
              taskAsyncState?.skip &&
              taskAsyncState?.dismissUntil &&
              taskAsyncState?.dismissUntil > now &&
              taskAsyncState?.scheduleId === schedule
            )
        ),

    [devicesWithUpdateRequiredConnected, now]
  );

  // fetch every 15 seconds all the information
  // if some schedule changes
  useInterval(() => {
    if (!usePeriodicFetch) return;
    refetchScheduleDevices();
  }, SECONDS_POOL_DATA * 1000);

  return {
    loadingScheduleDevices,
    loadingDevices,
    refetchScheduleDevices,
    isFetchingScheduleDevices,
    devicesNeedUpdateRequired,
    hasSomeDeviceDisconnected,
    devicesWithUpdateRequiredConnected,
    devicesWithUpdateRequired,
    scheduleDevices,
  } as const;
}

export default useDeviceWithRequireUpdateSchedules;
