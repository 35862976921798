import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalInfo } from '../../../../../theme/ui/Atoms/Modal';
import Button from '../../../../../theme/ui/Atoms/Button';
import useErrorMessage from '../../../../../shared/hooks/useErrorMessage';
import { useAppDispatch } from '../../../../../redux/store.model';
import useDeviceWithRequireUpdateSchedules from './useDeviceWithRequireUpdateSchedules';
import { setScheduleRequireUpdateAsyncThunk } from '../redux/actions/asyncThunks';
import useParseErrorMessage from '../../../../../shared/hooks/useParseErrorMessage';
import { asyncTasksActions } from '../redux/slice/AsyncTasksSlice';
import StoreAPI from '../../../../../service/store/StoreAPI';

export default function DeviceScheduleChangeDetectorModal() {
  const { t } = useTranslation<string>();
  const {
    devicesNeedUpdateRequired,
    devicesWithUpdateRequiredConnected,
    hasSomeDeviceDisconnected,
    isFetchingScheduleDevices,
    refetchScheduleDevices,
    loadingScheduleDevices,
  } = useDeviceWithRequireUpdateSchedules(true);

  const dispatch = useAppDispatch();
  const errorMessage = useErrorMessage();
  const parseErrorMessage = useParseErrorMessage();

  const [updating, setUpdating] = useState(false);
  const onClickConfirm = () => {
    Promise.resolve(setUpdating(true))
      .then(() =>
        Promise.all(
          devicesWithUpdateRequiredConnected.map(({ device, schedule }) =>
            dispatch(
              setScheduleRequireUpdateAsyncThunk({
                deviceId: device?.uuid ?? '',
                scheduleId: schedule,
              })
            ).unwrap()
          )
        )
      )
      .then(() => refetchScheduleDevices())
      .then(() => dispatch(StoreAPI.util.invalidateTags(['schedules-actions'])))
      .catch(errorMessage)
      .finally(() => setUpdating(false));
  };

  const onClickDismiss = () => {
    Promise.resolve(setUpdating(true))
      .then(() =>
        dispatch(
          asyncTasksActions.skipScheduleRequireUpdate(
            devicesWithUpdateRequiredConnected.map(({ device, schedule }) => ({
              deviceId: device?.uuid ?? '',
              scheduleId: schedule,
            }))
          )
        )
      )
      .catch(errorMessage)
      .finally(() => setUpdating(false));
  };

  const isBusy =
    isFetchingScheduleDevices || loadingScheduleDevices || updating;
  const showModal = !!devicesNeedUpdateRequired.length;
  return (
    <ModalInfo
      open={showModal}
      description={
        (
          <>
            <p>{t('schedule.settings.modal_update_schedule.description')}</p>
            <ul>
              {devicesNeedUpdateRequired.map(({ device, taskAsyncState }) => {
                return (
                  <li key={device?.uuid}>
                    {device?.storeName}: {device?.unitName}{' '}
                    {!device?.deviceState?.mqttConnected
                      ? t('operator.modal_update.device_disconnected')
                      : ''}
                    {taskAsyncState?.loading ? `(${t('general.loading')})` : ''}
                    {taskAsyncState?.error
                      ? `(Error: ${parseErrorMessage(taskAsyncState?.error)})`
                      : ''}
                  </li>
                );
              })}
            </ul>
            <p>
              {hasSomeDeviceDisconnected
                ? t('operator.modal_update.description_action_disconnected')
                : t('operator.modal_update.description_action')}
            </p>
          </>
        ) ?? ''
      }
      title={t('operator.modal_update.title')}
      buttonActions={
        <>
          <Button
            autoFocus
            variant="text"
            onClick={onClickDismiss}
            disabled={isBusy}
          >
            {t('operator.modal_update.dismiss_today')}
          </Button>
          <Button
            autoFocus
            variant="contained"
            onClick={onClickConfirm}
            loading={isBusy}
          >
            {t('button.confirm')}
          </Button>
        </>
      }
    />
  );
}
